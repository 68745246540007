import { FunctionComponent, HTMLAttributes, PropsWithChildren } from "react"

declare global {
    namespace JSX {
        interface IntrinsicElements {
            ["fixed-overlay"]: unknown;
        }
    }
}

interface Props {
    dispatch?: () => void;
}

const FixedOverlay: FunctionComponent<PropsWithChildren & Props> = ({ children, dispatch }) => {
    return (
        <fixed-overlay is='div'>
            <div
                tabIndex={-1}
                className={`z-50 items-center justify-center h-[calc(100dvh)] overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 w-full inset-0`}
                onClick={dispatch}
            >
                {children}
            </div>
        </fixed-overlay>
    )
}

export default FixedOverlay
