/* eslint-disable react-hooks/exhaustive-deps */
import React, { FunctionComponent, useEffect, useRef, useState } from "react"
import { MeilisearchDocument } from "@openlaw-au/types"
import { useKeyPress } from "../../util/util"
import FocusWrap from "../shared/FocusWrap"
import Suggester from "./Suggester"
import HTTPClient from "../../util/HttpClient"
import { AdvancedSearchFields } from "../../hooks/useSearchPageStateAndHandlers"
import { JurisdictionsTrunc } from "../../requests/search-queries"
import { JASMINE_ENDPOINT } from "../../util/consts"

interface Props {
    defaultQuery: string;
    handleRunSearch: (query: string, opt: number) => void;
    advSearchState?: AdvancedSearchFields;
}

declare global {
    namespace JSX {
        interface IntrinsicElements {
            ["super-search"]: unknown;
            ["suggestions"]: unknown;
        }
    }
}

export const DefaultJurisdictionMap = {
    HCA: true,
    FCAFC: true,
    NSWCA: true,
    NSWCCA: true,
    VSCA: true,
    QCA: true,
    WASCA: true,
    TASFC: true,
    SASCA: true,
    SASCFC: true,
    NTCA: true,
    ACTCA: true,
}

// CSS Classes
const greenBorder = "green-border"
const greyBorder = "grey-border"

const SuperSearch: FunctionComponent<Props> = ({ defaultQuery, handleRunSearch, advSearchState }) => {
    const [searchOpt, setSearchOpt] = useState<number>(0)
    const [inputVal, setInputVal] = useState<string>(defaultQuery)
    const [suggestions, setSuggestions] = useState<MeilisearchDocument[]>([])
    const [focus, setFocus] = useState<boolean>(false)
    const [inputError, setInputError] = useState<string>("")
    const inputRef = useRef<HTMLInputElement>(null)

    const handleSetFocus = (focus: boolean) => {
        setFocus(focus)
    }

    const handleSearchInputChange = (changeEvent: React.ChangeEvent<HTMLInputElement>) => {
        changeEvent.preventDefault()
        setInputVal(changeEvent.target.value)
    }

    const handleSearchInputKeyDown = (e: React.KeyboardEvent & React.ChangeEvent<HTMLInputElement>) => {
        if (e.code === "Enter") {
            e.preventDefault()
            setFocus(false)
            if (!e.target.value) {
                setInputError("Please enter a question or select a suggestion")
                return
            }
            setInputVal(e.target.value)
            handleRunSearch(e.target.value, searchOpt)
        }
    }

    const handleSetSearchOption = (n: number) => {
        setSearchOpt(n)
    }

    const renderSuggestionsContent = (focusState: boolean, suggestionData: MeilisearchDocument[]) => {
        if (focusState) {
            return (
                <Suggester
                    searchOpt={searchOpt}
                    handleSetSearchOption={handleSetSearchOption}
                    setFocus={handleSetFocus}
                    handleSearch={handleSuggestionSearch}
                    suggestionData={suggestionData}
                    handleInputSearch={handleSearch}
                />
            )
        }
    }

    const handleSetPlaceholder = (errState: string) => {
        if (errState) {
            return errState
        }
        return "Search case law..."
    }

    /***
     * Gets suggestions as the user types out their search.
     */
    useEffect(() => {
        const Client: HTTPClient = new HTTPClient(JASMINE_ENDPOINT)

        const run = async () => {
            try {
                const resp = await Client.post("/api/e2/quicksearch/multi", {
                    search_term: inputVal,
                    indexes:
                        advSearchState && Object.values(advSearchState).length > 0
                            ? Object.entries(advSearchState).reduce(
                                (acc, [k, v]) => (v ? [...acc, k] : acc),
                                  [] as string[]
                            )
                            : Object.entries(DefaultJurisdictionMap).map((o) => o[0]),
                    offset: 0,
                    limit: 5,
                })
                setSuggestions(resp.data as MeilisearchDocument[])
            } catch (err) {
                // TODO: sentry error handling?
                // console.error(err)
            }
        }
        if (inputVal.length > 0 && inputVal.length < 35 && !(inputVal.length > 1 && suggestions.length < 1)) {
            run()
        }
    }, [inputVal])

    /***
     * Sets the focus state based on focus status of the input element
     * and clears the searchInput state if query data from parent is
     * undefined
     */
    useEffect(() => {
        if (focus) {
            (inputRef.current as HTMLInputElement).focus()
        } else {
            (inputRef.current as HTMLInputElement).blur()
        }
        if (!defaultQuery) {
            setInputVal("")
        }
    }, [focus])

    /***
     * Reverts the search input placeholder to its default after x time
     */
    // useEffect(() => {
    //     if (inputError) {
    //         setTimeout(() => {
    //             setInputError("");
    //         }, 15000);
    //     }
    // }, [inputError]);

    /***
     *  Handle shortcut for opening up the searchbox (Cmd-k)
     * */
    const handleToggleFocus = (manualEscape: boolean) => {
        if (document.activeElement === inputRef.current || manualEscape) {
            inputRef?.current?.blur()
        } else {
            inputRef?.current?.focus()
        }
    }

    /**
     * Handle search when suggested query is selected
     * @param {string} suggestedQuery
     */
    const handleSuggestionSearch = (suggestedQuery: string) => {
        setInputVal(suggestedQuery)
        handleRunSearch(suggestedQuery, searchOpt)
    }

    /**
     * Handle search on input
     * @param {React.MouseEvent<HTMLButtonElement>} event
     */
    const handleSearch = (event: React.MouseEvent<HTMLButtonElement | HTMLDivElement>) => {
        event.preventDefault()

        if (!inputVal && inputRef?.current) {
            setInputError("Please enter a question or select a suggestion")
            return
        }
        handleRunSearch(inputVal, searchOpt)
    }

    useKeyPress((manualEscape: boolean) => handleToggleFocus(manualEscape))

    return (
        <>
            <FocusWrap shouldFocus={focus}>
                <super-search is='div' className={`${focus ? greenBorder : greyBorder}`}>
                    <input
                        type='text'
                        name='q'
                        ref={inputRef}
                        value={inputVal}
                        onChange={handleSearchInputChange}
                        onKeyDown={handleSearchInputKeyDown}
                        onClick={() => setFocus(true)}
                        onFocus={() => setFocus(true)}
                        onBlur={() => setFocus(false)}
                        autoComplete='off'
                        autoCapitalize='on'
                        placeholder={handleSetPlaceholder(inputError)}
                        className={`${
                            inputError ? "input-error" : ""
                        } font-sourceSansPro text-gray-900 subpixel-antialiased focus:ring-0 border-0`}
                    />
                    {/* {!focus && ( */}
                    <div className={`run-search-button`}>
                        <button
                            onMouseDown={(e) => {
                                handleSearch(e)
                            }}
                        ></button>
                    </div>
                    {/* )} */}
                    <div>{renderSuggestionsContent(focus, suggestions)}</div>
                </super-search>
            </FocusWrap>
            {focus ? <div className='h-[3.25rem]' /> : null}
        </>
    )
}

export default SuperSearch
