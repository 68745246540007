import React, { FunctionComponent, ReactNode } from "react"
import FixedOverlay from "./FixedOverlay"

interface Props {
    children?: ReactNode;
    shouldFocus: boolean;
}

const FocusWrap: FunctionComponent<Props> = ({ children, shouldFocus }) => {
    return shouldFocus ? (
        <FixedOverlay>
            <div
                className={`bg-unfocussed-gray overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center`}
            >
                {children}
            </div>
        </FixedOverlay>
    ) : (
        <>{children}</>
    )
}

export default FocusWrap
