import { FunctionComponent } from "react"
import Image from "next/image"

declare global {
    namespace JSX {
        interface IntrinsicElements {
            ["search-info-box"]: unknown;
        }
    }
}

interface SearchInfoBoxProps {
    handleSetSearchOption: (n: number) => void;
    searchOption: number;
    handleInputSearch: (e: React.MouseEvent<HTMLButtonElement | HTMLDivElement>) => void;
}

const SearchInfoBox: FunctionComponent<SearchInfoBoxProps> = ({
    handleSetSearchOption,
    searchOption,
    handleInputSearch,
}) => {
    return (
        <search-info-box is='div'>
            <button
                onClick={(e) => {
                    e.preventDefault()
                    handleSetSearchOption(0)
                }}
                onMouseDown={(e) => e.preventDefault()}
                className={searchOption === 0 ? "rounded-md ring-2 ring-jade-primary" : ""}
            >
                <Image height={72} width={72} src={"/logos/jasmine.svg"} alt={"Jasmine logo"} />
                <div>
                    <div className='flex flex-row justify-between'>
                        <p className='info-box-heading'>Semantic Search</p>
                        {searchOption === 0 && <svg className='h-6 w-6 bg-no-repeat bg-contain bg-check -mr-2' />}
                    </div>
                    <p className='info-box-desc'>
                        Find authoritative appellate court decision by asking natural language search queries.
                    </p>
                </div>
            </button>
            <button
                onClick={(e) => {
                    e.preventDefault()
                    handleSetSearchOption(1)
                }}
                onMouseDown={(e) => e.preventDefault()}
                className={searchOption === 1 ? "rounded-md ring-2 ring-jade-primary" : ""}
            >
                <Image height={68} width={68} src={"/logos/jade-light.svg"} alt={"JADE logo"} />
                <div>
                    <div className='flex flex-row justify-between'>
                        <p className='info-box-heading'>Keyword Search</p>
                        {searchOption === 1 && <svg className='h-6 w-6 bg-no-repeat bg-contain bg-check -mr-2' />}
                    </div>
                    <p className='info-box-desc'>
                        Utilise the comprehensive coverage of Australian case law in JADE to search for case names.
                    </p>
                </div>
            </button>
        </search-info-box>
    )
}

export default SearchInfoBox
